import React, { useState, useEffect } from 'react';
import { Mail, AlertCircle, Loader2 } from 'lucide-react';
import { API_CONFIG } from './config';

// A reusable text input field with built-in validation/error display
const FormField = ({
  label,
  name,
  type = 'text',
  required = false,
  value,
  onChange,
  error,
  placeholder,
  step,
  min,
  max,
}) => (
  <div className="mb-4">
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
    <input
      type={type}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      aria-invalid={!!error}
      aria-describedby={error ? `${name}-error` : undefined}
      step={step}
      min={min}
      max={max}
      className={`mt-1 block w-full rounded-md shadow-sm p-2 
        ${error ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-indigo-500'}
        focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2
        disabled:bg-gray-100 disabled:cursor-not-allowed`}
      disabled={false}
    />
    {error && (
      <p
        className="mt-1 text-sm text-red-600"
        id={`${name}-error`}
        role="alert"
      >
        {error}
      </p>
    )}
  </div>
);

// A simple loading spinner icon for asynchronous operations
const LoadingIndicator = () => <Loader2 className="w-5 h-5 animate-spin" />;

/**
 * Validate the formData object and return an object of errors.
 * Returns an empty object if no errors found.
 */
const validateFormData = (formData) => {
  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateNameField = (fieldName, fieldValue, label) => {
    if (!fieldValue.trim()) {
      errors[fieldName] = `${label} is required`;
    } else if (fieldValue.length < 2) {
      errors[fieldName] = `${label} must be at least 2 characters`;
    }
  };

  validateNameField('studentName', formData.studentName, 'Student Name');
  validateNameField('parentName', formData.parentName, 'Parent Name');

  // Student Email
  if (!formData.studentEmail.trim()) {
    errors.studentEmail = 'Student Email is required';
  } else if (!emailRegex.test(formData.studentEmail)) {
    errors.studentEmail = 'Invalid email address';
  }

  // Parent Email
  if (!formData.parentEmail.trim()) {
    errors.parentEmail = 'Parent Email is required';
  } else if (!emailRegex.test(formData.parentEmail)) {
    errors.parentEmail = 'Invalid email address';
  }

  // GPA
  const gpa = parseFloat(formData.gpa);
  if (!formData.gpa) {
    errors.gpa = 'GPA is required';
  } else if (isNaN(gpa) || gpa < 0 || gpa > 4) {
    errors.gpa = 'GPA must be between 0 and 4';
  }

  // SAT Score
  const satScore = parseInt(formData.satScore, 10);
  if (!formData.satScore) {
    errors.satScore = 'SAT Score is required';
  } else if (isNaN(satScore) || satScore < 400 || satScore > 1600) {
    errors.satScore = 'SAT Score must be between 400 and 1600';
  }

  // Academic Focus
  if (!formData.focus.trim()) {
    errors.focus = 'Academic Focus is required';
  }

  return errors;
};

/**
 * Handle error reporting to the backend error endpoint.
 * Logs locally if reporting fails.
 */
const reportError = async (error, context = {}) => {
  try {
    console.log('Reporting error to:', `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.REPORT_ERROR}`);
    await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.REPORT_ERROR}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        message: error.message,
        stack: error.stack,
        url: window.location.href,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
        ...context,
      }),
    });
  } catch (reportErr) {
    console.error('Failed to report error:', reportErr);
  }
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    studentName: '',
    studentEmail: '',
    parentName: '',
    parentEmail: '',
    gpa: '',
    satScore: '',
    focus: '',
  });

  const [status, setStatus] = useState({
    submitting: false,
    success: null,
    error: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      // Clear error for this field if it was previously set
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateFormData(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      // If there are validation errors, do not submit
      return;
    }

    setStatus({ submitting: true, success: null, error: null });
    console.log('Submitting to:', `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.SUBMIT_FORM}`);

    try {
      const response = await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.SUBMIT_FORM}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
          ...formData,
          timestamp: new Date().toISOString(),
          source: window.location.href,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'An error occurred while processing your request');
      }

      // If success
      setStatus({
        submitting: false,
        success: 'Your personalized college plan request has been received! Please check your email.',
        error: null,
      });

      // Clear the form after successful submission
      setFormData({
        studentName: '',
        studentEmail: '',
        parentName: '',
        parentEmail: '',
        gpa: '',
        satScore: '',
        focus: '',
      });

      // Scroll to top to show success message prominently
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (submitError) {
      console.error('Submission error:', submitError);
      await reportError(submitError, { formData });

      setStatus({
        submitting: false,
        success: null,
        error: 'There was an error submitting your request. Please try again later.',
      });
    }
  };

  // Global error reporting for unhandled errors
  useEffect(() => {
    const handleWindowError = async (message, source, lineno, colno, error) => {
      try {
        await fetch(`${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.REPORT_ERROR}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          mode: 'cors',
          body: JSON.stringify({
            message: error?.message || message,
            stack: error?.stack,
            url: window.location.href,
            lineNo: lineno,
            columnNo: colno,
            source,
            timestamp: new Date().toISOString(),
            userAgent: navigator.userAgent,
          }),
        });
      } catch (reportErr) {
        console.error('Failed to report global error:', reportErr);
      }
    };

    window.onerror = handleWindowError;

    const handleUnhandledRejection = (event) => {
      handleWindowError(
        event.reason?.message || 'Unhandled Promise Rejection',
        window.location.href,
        0,
        0,
        event.reason
      );
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.onerror = null;
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6 text-gray-900">
        Get Your Personalized College Plan
      </h3>

      {(status.success || status.error) && (
        <div
          className={`mb-6 p-4 ${
            status.success ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          } rounded-md flex items-center shadow-sm`}
          role="alert"
        >
          {status.success ? <Mail className="w-5 h-5 mr-2 flex-shrink-0" /> : <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />}
          <span className="flex-1">{status.success || status.error}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6" noValidate>
        <FormField
          label="Student Name"
          name="studentName"
          required
          value={formData.studentName}
          onChange={handleChange}
          error={errors.studentName}
          placeholder="John Doe"
        />

        <FormField
          label="Student Email"
          name="studentEmail"
          type="email"
          required
          value={formData.studentEmail}
          onChange={handleChange}
          error={errors.studentEmail}
          placeholder="john@example.com"
        />

        <FormField
          label="Parent Name"
          name="parentName"
          required
          value={formData.parentName}
          onChange={handleChange}
          error={errors.parentName}
          placeholder="Jane Doe"
        />

        <FormField
          label="Parent Email"
          name="parentEmail"
          type="email"
          required
          value={formData.parentEmail}
          onChange={handleChange}
          error={errors.parentEmail}
          placeholder="jane@example.com"
        />

        <FormField
          label="GPA"
          name="gpa"
          type="number"
          step="0.01"
          min="0"
          max="4"
          required
          value={formData.gpa}
          onChange={handleChange}
          error={errors.gpa}
          placeholder="3.75"
        />

        <FormField
          label="SAT Score"
          name="satScore"
          type="number"
          min="400"
          max="1600"
          required
          value={formData.satScore}
          onChange={handleChange}
          error={errors.satScore}
          placeholder="1450"
        />

        <FormField
          label="Academic Focus"
          name="focus"
          required
          value={formData.focus}
          onChange={handleChange}
          error={errors.focus}
          placeholder="Computer Science"
        />

        <button
          type="submit"
          disabled={status.submitting}
          className={`w-full flex items-center justify-center bg-indigo-600 text-white px-6 py-3 rounded-md 
            font-semibold hover:bg-indigo-700 transition-colors focus:outline-none focus:ring-2 
            focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed
            disabled:hover:bg-indigo-600`}
        >
          {status.submitting ? (
            <div className="flex items-center space-x-2">
              <span>Submitting...</span>
              <LoadingIndicator />
            </div>
          ) : (
            'Get College Plan'
          )}
        </button>

        <p className="mt-4 text-sm text-gray-500 text-center">
          By submitting this form, you consent to receive your personalized college plan via email. 
          We respect your privacy and will never share your information with third parties.
        </p>
      </form>
    </div>
  );
};

export default ContactForm;
