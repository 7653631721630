// src/config.js

export const API_CONFIG = {
    BASE_URL: import.meta.env.VITE_API_URL || 'https://5pqqqih2i0.execute-api.us-east-1.amazonaws.com/prod',
    ENDPOINTS: {
      SUBMIT_FORM: '/submit-form',
      REPORT_ERROR: '/report-error',
      HEALTH: '/health'
    }
  };
  