// src/components/FAQItem.jsx

import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const FAQItem = ({ id, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 pb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between items-center text-left focus:outline-none"
        aria-expanded={isOpen}
        aria-controls={`faq-answer-${id}`}
      >
        <span className="text-lg font-medium">{question}</span>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-gray-600" aria-hidden="true" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-600" aria-hidden="true" />
        )}
      </button>
      {isOpen && (
        <div
          id={`faq-answer-${id}`}
          className="mt-2 text-gray-600"
          role="region"
          aria-labelledby={`faq-question-${id}`}
        >
          {answer}
        </div>
      )}
    </div>
  );
};

export default FAQItem;
