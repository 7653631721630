// src/components/ScheduleCall.jsx

import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Calendar, ExternalLink } from 'lucide-react';
import { InlineWidget } from 'react-calendly';

// ------------------------
// Reusable Components
// ------------------------

// Tooltip Component
const Tooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-xs text-white bg-gray-800 rounded whitespace-nowrap z-50">
          {content}
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 border-4 border-transparent border-t-gray-800" />
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};

// ------------------------
// Animation Variants
// ------------------------

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: { duration: 0.3 },
  },
};

// ------------------------
// ScheduleCall Component
// ------------------------

const ScheduleCall = () => {
  const calendlyLink = 'https://calendly.com/ascendconsulting-info/30min'; // Your actual Calendly link

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-6">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Schedule a Call - Ascend Consulting</title>
        <meta
          name="description"
          content="Schedule your free consultation with Ascend Consulting to discuss your college admissions strategy."
        />
        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Schedule a Call - Ascend Consulting"
        />
        <meta
          property="og:description"
          content="Schedule your free consultation with Ascend Consulting to discuss your college admissions strategy."
        />
        <meta
          property="og:url"
          content="https://www.ascendconsulting.com/schedule-call"
        />
        {/* Optional: Add og:image if available */}
      </Helmet>

      <motion.div
        className="bg-white p-8 rounded-lg shadow-md w-full max-w-3xl relative"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        {/* Header */}
        <div className="flex items-center space-x-2 mb-6">
          <Calendar className="text-indigo-600 w-6 h-6" aria-hidden="true" />
          <h2 className="text-2xl font-bold">Schedule a Free Consultation</h2>
        </div>

        {/* Description */}
        <p className="text-gray-700 mb-6">
          Please choose a date and time that works best for you. Our expert
          counselors are ready to assist you in crafting your personalized
          college admissions strategy.
        </p>

        {/* Calendly Widget */}
        <div className="rounded-md overflow-hidden">
          <InlineWidget
            url={calendlyLink}
            styles={{ height: '700px' }}
            iframeProps={{
              allowTransparency: 'true',
              frameBorder: '0',
              scrolling: 'no',
            }}
          />
        </div>

        {/* Fallback Link */}
        <div className="mt-4 text-center">
          <Tooltip content="Open Calendly in a new tab">
            <a
              href={calendlyLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-indigo-600 hover:text-indigo-800 underline"
            >
              <ExternalLink className="w-4 h-4 mr-1" aria-hidden="true" />
              Click here if you are having trouble loading the scheduler.
            </a>
          </Tooltip>
        </div>
      </motion.div>
    </div>
  );
};

export default ScheduleCall;
