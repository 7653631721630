// src/components/SchoolsSection.jsx

import React from "react";
import { motion } from "framer-motion";

// Importing logos
import columbiaLogo from "../assets/logos/columbia-logo.png";
import cornellLogo from "../assets/logos/cornell.png";
import harvardLogo from "../assets/logos/harvard-logo.png";
import mitLogo from "../assets/logos/mit.png";
import nyuSternLogo from "../assets/logos/nyu-stern.png";
import nyuLogo from "../assets/logos/nyu.png";
import upennLogo from "../assets/logos/upenn.png";
import yaleLogo from "../assets/logos/yale-logo.png"; // New Import

// Motion Variants for Animations
const fadeIn = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: (custom) => ({
    opacity: 1,
    scale: 1,
    transition: { delay: custom * 0.2, duration: 0.6 },
  }),
};

// Schools Data Array
const schools = [
  {
    name: "Columbia University",
    logo: columbiaLogo,
  },
  {
    name: "Cornell University",
    logo: cornellLogo,
  },
  {
    name: "Harvard University",
    logo: harvardLogo,
  },
  {
    name: "Massachusetts Institute of Technology (MIT)",
    logo: mitLogo,
  },
  {
    name: "New York University (NYU) Stern School of Business",
    logo: nyuSternLogo,
  },
  {
    name: "New York University (NYU)",
    logo: nyuLogo,
  },
  {
    name: "University of Pennsylvania",
    logo: upennLogo,
  },
  {
    name: "Yale University", // New School
    logo: yaleLogo,         // Associated Logo
  },
  // Add more schools as needed
];

const SchoolsSection = () => {
  return (
    <section
      className="py-20 bg-white"
      aria-labelledby="schools-section-heading"
    >
      <div className="container mx-auto px-6">
        <h2
          id="schools-section-heading"
          className="text-3xl font-bold mb-6 text-center text-gray-900"
        >
          Schools Our Counselors Have Represented &amp; Attended
        </h2>
        <p className="text-lg text-gray-700 mb-12 text-center max-w-2xl mx-auto">
          Our dedicated counselors have firsthand experience attending these prestigious institutions, ensuring you receive informed and personalized guidance tailored to your academic aspirations.
        </p>
        <div className="schools-grid">
          {schools.map((school, index) => (
            <motion.div
              key={index}
              custom={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={fadeIn}
              className="school-logo-container hover-scale-up transition-smooth"
            >
              <img
                src={school.logo}
                alt={`${school.name} Logo`}
                className="school-logo-image"
                loading="lazy"
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SchoolsSection;
