// src/pages/ResourceDetailPage.jsx

import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ChevronLeft, Clock } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // Optional: For GitHub Flavored Markdown

import resources from '../data/Resources.js'; // Ensure correct path and casing

const ResourceDetailPage = () => {
  const { id } = useParams();

  // Memoize the resource lookup for performance optimization
  const resource = useMemo(() => {
    return resources.find((res) => res.id === parseInt(id, 10));
  }, [id]);

  if (!resource) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <div className="text-center px-4">
          <h2 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">
            Resource Not Found
          </h2>
          <Link
            to="/resources"
            className="text-blue-600 hover:text-blue-800 font-semibold inline-flex items-center"
            aria-label="Back to Resources"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Resources
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div
      className="py-20 bg-gray-100 dark:bg-gray-900"
      aria-labelledby="resource-detail-heading"
    >
      {/* SEO Meta Tags */}
      <Helmet>
        <title>{resource.title} - Ascend Consulting</title>
        <meta name="description" content={resource.description} />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={`${resource.title} - Ascend Consulting`}
        />
        <meta property="og:description" content={resource.description} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        {/* Add more meta tags as needed */}
      </Helmet>

      <div className="container mx-auto px-6 max-w-3xl">
        {/* Breadcrumb Navigation */}
        <nav className="text-sm mb-4" aria-label="Breadcrumb">
          <ol className="list-none p-0 inline-flex">
            <li className="flex items-center">
              <Link to="/" className="text-blue-600 hover:text-blue-800">
                Home
              </Link>
              <ChevronLeft size={16} className="mx-2 text-gray-500" />
            </li>
            <li className="flex items-center">
              <Link
                to="/resources"
                className="text-blue-600 hover:text-blue-800"
              >
                Resources
              </Link>
              <ChevronLeft size={16} className="mx-2 text-gray-500" />
            </li>
            <li
              className="text-gray-700 dark:text-gray-300"
              aria-current="page"
            >
              {resource.title}
            </li>
          </ol>
        </nav>

        {/* Resource Title */}
        <motion.h1
          id="resource-detail-heading"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl md:text-5xl font-bold mb-4 text-gray-900 dark:text-white"
        >
          {resource.title}
        </motion.h1>

        {/* Category and Read Time */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="flex items-center space-x-4 mb-4"
        >
          <span
            className="inline-block bg-blue-100 text-blue-800 text-xs md:text-sm px-2 py-1 rounded-full dark:bg-blue-900 dark:text-blue-300"
            aria-label={`Category: ${resource.category}`}
          >
            {resource.category}
          </span>
          <span
            className="text-gray-500 text-sm md:text-base flex items-center dark:text-gray-400"
            aria-label={`Estimated read time: ${resource.readTime}`}
          >
            <Clock size={16} className="inline mr-1" aria-hidden="true" />
            {resource.readTime}
          </span>
        </motion.div>

        {/* Resource Content */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="prose prose-lg text-gray-700 dark:text-gray-300"
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {resource.content}
          </ReactMarkdown>
        </motion.div>

        {/* Back to Resources Link */}
        <div className="mt-8">
          <Link
            to="/resources"
            className="text-blue-600 hover:text-blue-800 font-semibold inline-flex items-center"
            aria-label="Back to Resources"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Resources
          </Link>
        </div>
      </div>
    </div>
  );
};

// Optional: Define PropTypes for better type checking
ResourceDetailPage.propTypes = {
  // Define prop types if you expect any props
};

export default React.memo(ResourceDetailPage);
