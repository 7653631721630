// src/components/CoreValue.jsx

import React from "react";

const CoreValue = ({ value }) => {
  const Icon = value.icon;

  return (
    <div className="core-value-card">
      <Icon size={48} className="core-value-icon" aria-hidden="true" />
      <h3 className="text-xl font-semibold text-gray-900 mb-2">
        {value.title}
      </h3>
      <p className="text-gray-700">{value.description}</p>
    </div>
  );
};

export default CoreValue;
